@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');


:root{
  --red: #e01616;
  --lightred: #ec3636;
  --darkred: #b91818;
  --textColor: rgb(48,48,48);
  --background: #f4eee0;
}

body{
  /* height: 1000vh; */
  /* padding-top: 150px; */
  transition: all 0.2s ease;
  /* background-color: var(--background); */
  background-color: transparent;
  

  /* background-image: url(img/Rocks2.jpg);
  background-position: center;
  background-repeat: no-repeat; */
}

.activeBody{
  padding-top: 150px;
}

*{
  font-family: 'PT Sans', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}

.LinkNoColor{
  color: black;
  text-decoration: none;
}

/**HomePage**/
.highlight{
  color: var(--red);
}

.MainInfo{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MainInfo h1{
  font-weight: 500;
  font-size: 1.8rem;
}

.MainInfo h2{
  font-weight: 400;
  font-size: 1.6rem;
}

.MainInfo p{
  margin-top: 2rem;
  width: 700px;
  text-align: justify;
  font-size: 1.2rem;
}

.ViewApartmentsBtn{
  width: 400px;
  height: 45px;
  border-radius: 0.2rem;
  border: 1px solid var(--red);
  color: rgb(218, 0, 0);
  font-size: 1.2rem;
  margin-top: 3rem;
  cursor: pointer;
  transition: all 0.2s ease !important;
  background-color: white;
  box-shadow: inset 0 0 0 0 var(--red);
}

.ViewApartmentsBtn:hover{
  color: white;
  box-shadow: inset 0 -45px 0 0 var(--red) ;
  transform: translateY(-5px) !important;
}

.ApartmaniInfo{
  text-align: center;
  margin-top: 4rem;
}

.ApartmaniInfo h2{
  font-weight: 500;
  font-size: 1.6rem;
}

.ApartmaniInfo p{
  margin: 0 auto;
  margin-top: 1rem;
  width: 800px;
}

.MainApartmentCards{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 4rem auto 5rem auto;
    /* width: 60%; */
    width: 1200px;
}

@media only screen and (max-width: 1300px){
  .MainApartmentCards{
    width: 90%;
  }
}



@media only screen and (max-width: 1000px){
  .MainApartmentCards{
    flex-direction: column;
    width: 90%;
    margin: 4rem auto 0 auto;
  }
  

  .MainApartmentCards .CardDiv{
    width: 500px;
    margin-bottom: 7rem;
  }
}

@media only screen and (max-width: 600px){

  .MainApartmentCards{
    width: 100%;
  }

  .MainApartmentCards .CardDiv{
    width: 92%;
  }

  .MainApartmentCards .CardDiv p{
    font-size: 0.95rem;
  }
}

@media only screen and (max-width: 800px){
  .MainInfo{
    width: 90%;
    margin: 0 auto;
  }

  .MainInfo p{
    width: 95%;
  }
  .MainInfo{
    width: 90%;
    margin: 0 auto;
  }

  .MainInfo h1{
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .MainInfo h2{
    font-size: 1.4rem;
    text-align: center;
  }

  .MainInfo p{
    font-size: 1.1rem;
  }

  .ViewApartmentsBtn{
    width: 300px;
  }
}

@media only screen and (max-width: 850px){
  .ApartmaniInfo{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .ApartmaniInfo h1{
    font-size: 1.5rem;
  }
  .ApartmaniInfo p{
    width: 95%;
  }
}


.navLink{
  margin-top: 2rem;
  margin-left: 250px;
  font-size: 0.9rem;
  color: var(--textColor);
}

.navLinks{
  margin-top: 1rem;
  color: black;
}


@media only screen and (max-width: 1600px){
  .navLink{
    margin-left: 5rem;
  }
}

@media only screen and (max-width: 800px){
  .navLink{
    font-size: 0.85rem;
    margin-left: 1rem;
  }
}



.red{
  color: var(--red);
}
.bold{
  font-weight: bold;
}


/***Scroll animations***/
[data-aos] {
  pointer-events: none;
}
.aos-animate {
  pointer-events: auto;
}
.aos-init[data-aos][data-aos].aos-animate {
  transform: unset;
}


