.BackgroundDiv{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../img/Rocks2.jpg");
    background-size: cover;
    background-position: center;
    z-index: -5;
}