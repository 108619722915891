.slider {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  
  .slide {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.7s ease-in-out;
    background-size: cover;
    background-position: center;
  }
  
  .slide.activeImage {
    opacity: 1;
  }
  
  .prev,
  .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 40px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .prev:hover,
  .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .prev {
    left: 0;
    padding: 16px;
  }
  
  .next {
    right: 0;
    padding: 16px;
  }


@media only screen and (max-width: 650px){
  .prev, .next{
    background-color: transparent;
  }

  .prev:hover, .next:hover{
    background-color: transparent;
  }
}