.CardsSection{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 4rem auto 8rem auto;
    width: 60%;
    min-width: 800px;
}

.CardDiv{
    width: 370px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: gray; */
}

/* .CardImage{
    width: 100%;
    height: auto;
    cursor: pointer;
} */

.CardImage{
    width: 100%;
    aspect-ratio: 4/3;
    background-position: center;
    background-size: cover;
    border-radius: 0.5rem;
}


.cardImageLink{
    width: 100%;
    height: auto;
    cursor: pointer;
}

.CardImage:hover{
    filter: opacity(0.9);
}

.CardDiv h2{
    color: var(--red);
    font-size: 1.4rem;
    font-weight: 500;
    margin-top: -1rem;
    margin-bottom: 0.7rem;
}

.CardDiv p{
    width: 80%;
    margin-bottom: 1rem;
    font-size: 1rem;
    text-align: center;
    color: var(--textcolor);
}

.CardDiv a{
    font-weight: 500;
    color: var(--darkred);
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 3rem;
}

.CardDiv a:hover{
    color: var(--lightred);
}

